import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
	Box,
	Drawer,
	Hidden,
	List,
	ListSubheader,
	makeStyles
} from '@material-ui/core';
import {
	PieChart as PieChartIcon,
	Clipboard as ClipboardIcon,
	Image as ImageIcon,
	Settings as SettingsIcon,
	Home as HomeIcon,
	Users as UsersIcon,
	Search as SearchIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
	{
		subheader: 'Painel',
		items: [
			{
				title: 'Painel de Controle',
				icon: PieChartIcon,
				href: '/app'
			},
			{
				title: 'Banner',
				icon: ImageIcon,
				href: '/app/banner'
			},
			{
				title: 'Lojas',
				icon: HomeIcon,
				href: '/app/store'
			},
			{
				title: 'Busca',
				icon: SearchIcon,
				items: [
					// {
					// 	title: 'Histórico',
					// 	href: '/app/search'
					// },
					{
						title: 'Sugestões',
						href: '/app/search-suggestion'
					},
				]
			},
			{
				title: 'Imóveis',
				icon: HomeIcon,
				items: [
					{
						title: 'Imóveis',
						href: '/app/realestates/realestate'
					},
					{
						title: 'Categorias',
						href: '/app/realestates/category'
					},
					{
						title: 'Corretores',
						href: '/app/realestates/agent'
					},
					{
						title: 'Tipos',
						href: '/app/realestates/type'
					},
					// {
					// 	title: 'Agendamento de Visita',
					// 	href: '/app/realestates/visit'
					// },
					// {
					// 	title: 'WhatsApp',
					// 	href: '/app/realestates/whatsapp'
					// }
				]
			},
			{
				title: 'Formulários',
				icon: ClipboardIcon,
				items: [
					{
						title: 'Anunciar Imóvel',
						href: '/app/contacts/announcerRealestate'
					},
					{
						title: 'Calculadora Financeira',
						href: '/app/contacts/calculator'
					},
					{
						title: 'Contatos',
						href: '/app/contacts/contact'
					},
					{
						title: 'Encontre seu Imóvel',
						href: '/app/contacts/findYourRealestate'
					},
					// {
					// 	title: 'Pré-cadastro',
					// 	href: '/app/contacts/preRegistration'
					// },
					{
						title: 'Simulador de Financiamento',
						href: '/app/contacts/financingSimulator'
					},
					{
						title: 'Newsletter',
						href: '/app/contacts/newsletter'
					},
          {
						title: 'Saber Mais',
						href: '/app/realestates/knowMore'
					},
				]
			},
			{
				title: 'Informações Gerais',
				icon: SettingsIcon,
				href: '/app/socialMedia',
			},
			{
				title: 'Usuários',
				icon: UsersIcon,
				href: '/app/user'
			}
		]
	},
	// {
	// 	subheader: 'Gestão',
	// 	items: [
	// 		{
	// 			title: 'Usuários',
	// 			icon: UserIcon,
	// 			href: '/app/user'
	// 		},
	// 	]
	// },
];

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({
	acc,
	pathname,
	item,
	depth = 0
}) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				key={key}
				info={item.info}
				open={Boolean(open)}
				title={item.title}
			>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items
				})}
			</NavItem>
		);
	} else {
		acc.push(
			<NavItem
				depth={depth}
				href={item.href}
				icon={item.icon}
				key={key}
				info={item.info}
				title={item.title}
			/>
		);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	}
}));

function NavBar({ openMobile, onMobileClose, }) {
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
		>
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box
						p={2}
						display="flex"
						justifyContent="center"
					>
						<RouterLink to="/">
							<Logo />
						</RouterLink>
					</Box>
				</Hidden>
				<Box p={2}>
					{navConfig.map((config) => (
						<List
							key={config.subheader}
							subheader={(
								<ListSubheader
									disableGutters
									disableSticky
								>
									{config.subheader}
								</ListSubheader>
							)}
						>
							{renderNavItems({ items: config.items, pathname: location.pathname })}
						</List>
					))}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
