// or get from process.env.REACTAPP{var} to handle PROD and DEV environments
let apiBaseUrl;
if(window.location.hostname === 'localhost') {
    apiBaseUrl = 'http://localhost/anage-novo/api/public/api';
}
else if(window.location.hostname === 'anage-homolog.emutuadigital.com') {
    apiBaseUrl = 'http://3.231.186.85/anage-novo/api/public/api';
}
else if(window.location.hostname === 'www-homolog.anageimoveis.com.br') {
    apiBaseUrl = 'https://api-homolog.anageimoveis.com.br/api';
}
else {
    apiBaseUrl = 'https://api.anageimoveis.com.br/api';
}

export const APP_VERSION = '1.0.0';
export const API_BASE_URL = apiBaseUrl;
export const ENABLE_REDUX_LOGGER = false;

export default {};
