/* eslint-disable react/no-array-index-key */
import React, {
    lazy,
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
    {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app" />
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: () => <Redirect to="/app/dashboard" />
            },

            {
                path: '/app/dashboard',
                exact: true,
                component: lazy(() => import('src/views/Dashboard'))
            },

            {
                path: '/app/banner',
                exact: true,
                component: lazy(() => import('src/views/Banner/Banner/List'))
            },
            {
                path: '/app/banner/create',
                exact: true,
                component: lazy(() => import('src/views/Banner/Banner/Details'))
            },
            {
                path: '/app/banner/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/Banner/Banner/Details'))
            },

            {
                path: '/app/store',
                exact: true,
                component: lazy(() => import('src/views/Store/Store/List'))
            },
            {
                path: '/app/store/create',
                exact: true,
                component: lazy(() => import('src/views/Store/Store/Details'))
            },
            {
                path: '/app/store/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/Store/Store/Details'))
            },

            {
                path: '/app/socialMedia',
                exact: true,
                component: lazy(() => import('src/views/SocialMedia/Details'))
            },
            {
                path: '/app/socialMedia/create',
                exact: true,
                component: lazy(() => import('src/views/SocialMedia/Details'))
            },
            {
                path: '/app/socialMedia/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/SocialMedia/Details'))
            },

            {
                path: '/app/search',
                exact: true,
                component: lazy(() => import('src/views/Search/Search/List'))
            },
            {
                path: '/app/search/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Search/Search/View'))
            },

            {
                path: '/app/search-suggestion',
                exact: true,
                component: lazy(() => import('src/views/Search/Suggestion/List'))
            },
            {
                path: '/app/search-suggestion/create',
                exact: true,
                component: lazy(() => import('src/views/Search/Suggestion/View'))
            },
            {
                path: '/app/search-suggestion/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/Search/Suggestion/View'))
            },

            {
                path: '/app/realestates/realestate',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Realestate/List'))
            },
            {
                path: '/app/realestates/realestate/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Realestate/Details'))
            },
            {
                path: '/app/realestates/agent',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Agent/List'))
            },
            {
                path: '/app/realestates/agent/create',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Agent/Details'))
            },
            {
                path: '/app/realestates/agent/:entityId/edit',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Agent/Details'))
            },
            {
                path: '/app/realestates/category',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Category/List'))
            },
            {
                path: '/app/realestates/type',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Type/List'))
            },
            {
                path: '/app/realestates/visit',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Visit/List'))
            },
            {
                path: '/app/realestates/visit/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Realestates/Visit/View'))
            },
            {
                path: '/app/realestates/knowMore',
                exact: true,
                component: lazy(() => import('src/views/Realestates/KnowMore/List'))
            },
            {
                path: '/app/realestates/knowMore/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Realestates/KnowMore/View'))
            },
            {
                path: '/app/realestates/whatsapp',
                exact: true,
                component: lazy(() => import('src/views/Realestates/Whatsapp/List'))
            },
            {
                path: '/app/realestates/whatsapp/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Realestates/Whatsapp/View'))
            },

            {
                path: '/app/contacts/announcerRealestate',
                exact: true,
                component: lazy(() => import('src/views/Contacts/AnnouncerRealestate/List'))
            },
            {
                path: '/app/contacts/announcerRealestate/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/AnnouncerRealestate/View'))
            },

            {
                path: '/app/contacts/calculator',
                exact: true,
                component: lazy(() => import('src/views/Contacts/Calculator/List'))
            },
            {
                path: '/app/contacts/calculator/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/Calculator/View'))
            },

            {
                path: '/app/contacts/contact',
                exact: true,
                component: lazy(() => import('src/views/Contacts/Contact/List'))
            },
            {
                path: '/app/contacts/contact/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/Contact/View'))
            },

            {
                path: '/app/contacts/financingSimulator',
                exact: true,
                component: lazy(() => import('src/views/Contacts/FinancingSimulator/List'))
            },
            {
                path: '/app/contacts/financingSimulator/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/FinancingSimulator/View'))
            },

            {
                path: '/app/contacts/findYourRealestate',
                exact: true,
                component: lazy(() => import('src/views/Contacts/FindYourRealestate/List'))
            },
            {
                path: '/app/contacts/findYourRealestate/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/FindYourRealestate/View'))
            },

            {
                path: '/app/contacts/newsletter',
                exact: true,
                component: lazy(() => import('src/views/Contacts/Newsletter/List'))
            },

            {
                path: '/app/contacts/preRegistration',
                exact: true,
                component: lazy(() => import('src/views/Contacts/PreRegistration/List'))
            },
            {
                path: '/app/contacts/preRegistration/:entityId',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/Contacts/PreRegistration/View'))
            },

            {
                path: '/app/user',
                exact: true,
                component: lazy(() => import('src/views/User/List/index'))
            },
            {
                path: '/app/user/create',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/User/Details/index.jsx'))
            },
            {
                path: '/app/user/:entityId/edit',
                exact: true,
                referer: false,
                component: lazy(() => import('src/views/User/Details/index.jsx'))
            },

            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
